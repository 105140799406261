export default {
  blogerName: 'DANGERCASINO',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/Dangerlive2'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@Dangerlive2024'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Dangerlive2020'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@danger_live2023'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@Danger-live'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/dangerlive2022?igsh=MTFya2ZvM2N3d3JhZw=='
    },
    {
      name: 'vk',
      url: 'https://vk.com/club225221173'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c0c2e0844',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c25947eb4',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'gizbo',
      url: 'https://gzb-blrs10.com/c8854cf3d',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c950028f0',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/cf06453d0',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c06194cf2',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c37ea18bc',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c7234d88d',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cd5368893',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c120dbb22',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c1029e579',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c7aeabaf6',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c08218a2d',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>DANGERCASINO</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'DANGERCASINO',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
